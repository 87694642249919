import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import { Seo } from "../utils/Seo.js";
import Layout from "../components/Layout/index.js";

const HomePage = ({ data }) => {
  useEffect(() => {
    const scripttag = document.createElement("script");
    scripttag.src = "https://embed.typeform.com/next/embed.js";
    document.body.appendChild(scripttag);
  }, []);

  return (
    <Layout>
      <Seo
        title="Send us a message, we'd love to hear from you! | Montebello.digital"
        desc="Want to make a bigger impact with your paid advertising & eCommerce? Fill in the form below."
      />
      <div className="agency-hero mb-3">
        <Container fluid className="agency-hero-container">
          <div className="hero-content">
            <h1 className="pre-title">Say hello.</h1>
            <h1 className="title">Send us a plain ol' email, or fill out the onboarding form below.</h1>
            <p className="mb-0">
                <a
                  style={{ textDecoration: "underline", color: "#fff" }}
                  href="mailto:contact@montebello.digital"
                >
                  contact@montebello.digital
                </a>
              </p>
          </div>
        </Container>
      </div>
      <div style={{marginTop: "-1rem", padding: "0rem 0 2rem", background: "var(--brand)" }}>
        <div
          data-tf-widget="BNMQNjz5"
          data-tf-opacity="100"
          data-tf-iframe-props="title=Client Inquiry & Pre-screening Form"
          data-tf-transitive-search-params
          data-tf-medium="snippet"
          style={{
            width: "100%",
            height: "500px",
          }}
        ></div>
      </div>
      {/* <script src="//embed.typeform.com/next/embed.js"></script> */}
    </Layout>
  );
};

export default HomePage;
